import React from "react"
import ImageMeta from "../components/ImageMeta"
import Layout from "../components/layout"
import Seamless from "../components/Seamless"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"

import TheaterVideo from "../components/Theater/TheaterVideo"
import Button from "@bit/azheng.joshua-tree.button"
import ReviewRow from "../components/ReviewRow"
import CTA from "../components/CTA/CTA"
import GetStarted from "../components/CTA/GetStarted"
import HeadingParagraphButton from "../components/CTA/HeadingParagraphButton"

import { Dialog } from "@blueprintjs/core"

import {
  Image,
  Video,
  Transformation,
  CloudinaryContext
} from "cloudinary-react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { instructions } from "../cms/collections/instructions"
import stars from "../images/stars.svg"
import VideoButton from "../components/Theater/VideoButton"

var classNames = require("classnames")

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
const converter = new showdown.Converter()

class iCatPage extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const post = this.props.data.allUniquePagesJson.nodes[0]
    return (
      <SharedStateProvider>
        <Layout pageTitle="i-cat-page">
          <SEO title={post.metaTitle} description={post.metaDescription} />

          <div>
            <Button buttonText="BACK" goBack />
            <section
              style={{ paddingBottom: post.imageId ? "80px" : "30px" }}
              className="procedure top-section section white-back"
            >
              <div className="columns">
                <div className="column is-4"></div>
                <div className="column">
                  <h1 style={{ textAlign: "center", marginBottom: "1rem" }}>
                    {post.heading}
                  </h1>

                  <div
                    dangerouslySetInnerHTML={createHtml(
                      converter.makeHtml(post.blurb)
                    )}
                  ></div>
                </div>
                <div className="column is-3"></div>
              </div>
            </section>
          </div>

          <div className="columns" style={{ paddingBottom: "80px" }}>
            <div className="column is-full-width has-text-centered">
              <TheaterVideo
                videoUrl={`https://www.youtube.com/watch?v=${post.youtube}`}
                controls={true}
                playing={true}
                overVideo={post.imageId ? true : false}
                onlyButton={post.imageId ? false : true}
              >
                {post.imageId && (
                  <ImageMeta
                    cloudName="nuvolum"
                    publicId={post.imageId}
                    width="auto"
                  ></ImageMeta>
                )}
              </TheaterVideo>
            </div>
          </div>

          <section
            className="body-sections section"
            style={{ paddingTop: "0", paddingBottom: "0" }}
          >
            <div className="columns">
              <div className="column is-5"></div>
              <div
                className="column"
                dangerouslySetInnerHTML={createHtml(
                  converter.makeHtml(post.body)
                )}
              ></div>
              <div className="column is-5"></div>
            </div>
          </section>

          <GetStarted
            className="get-started-cta"
            sideColumnIs={5}
            subheading={post.getStarted.subheading}
            heading={post.getStarted.heading}
            paragraph={
              <div
                dangerouslySetInnerHTML={createHtml(
                  converter.makeHtml(post.getStarted.blurb)
                )}
              ></div>
            }
            buttonText={post.getStarted.buttonText}
            buttonUrl={post.getStarted.href}
          />
        </Layout>
      </SharedStateProvider>
    )
  }
}

export const pageQuery = graphql`
  query threeDImagingPage($loneUrl: String!) {
    allUniquePagesJson(filter: { title: { eq: $loneUrl } }) {
      nodes {
        title
        heading
        blurb
        metaTitle
        metaDescription
        youtube
        body
        getStarted {
          blurb
          buttonText
          heading
          href
          subheading
        }
      }
    }
  }
`

export default iCatPage
